import React from "react";
import Footer from "../Footer";

const VocalReplyTermsOfService = () => {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>Terms of Service for Vocal Reply</h1>
					<VocalReplyTermsOfServiceContent />
				</div>
			</section>
		</main>
	);
};

const VocalReplyTermsOfServiceContent = () => {
	return (
		<div className="legal">
			<div className="legal-header">
				<p>
					<strong>Last Revised:</strong> 01 November 2024
				</p>
			</div>
			<article>
				<article>
					<h2>1. Introduction</h2>
					<p>
						These terms of service (“Terms”) apply to your access and use of the Vocal Reply iOS app (the
						“Service”). Please read them carefully.
					</p>
				</article>

				<article>
					<h2>2. Accepting these Terms</h2>
					<p>
						If you access or use the Service, it means you agree to be bound by all of the terms below. So,
						before you use the Service, please read all of the terms. If you don’t agree to all of the terms
						below, please do not use the Service. If you have any questions or concerns about these terms,
						please contact us.
					</p>
				</article>

				<article>
					<h2>3. Changes to these Terms</h2>
					<p>
						We reserve the right to modify these Terms at any time. For example, we may need to update these
						Terms if we introduce a new feature or for other reasons.
					</p>
					<p>
						Whenever we make changes to these Terms, the changes are effective immediately after we post the
						revised Terms (indicated by updating the “last modified” date) or upon your acceptance if we
						provide a mechanism for immediate acceptance (such as a click-through agreement). It is your
						responsibility to review these Terms periodically for changes.
					</p>
					<p>
						Your continued use of the Service after any changes indicates your acceptance of the new Terms.
					</p>
				</article>

				<article>
					<h2>4. Third-Party Services</h2>
					<p>
						Vocal Reply may link to third-party services, such as Firebase, RevenueCat, and OpenAI’s APIs,
						that we do not own or control. Your use of these third-party services is governed by their
						respective terms of service and privacy policies. We recommend you review their terms before
						using those services.
					</p>
				</article>

				<article>
					<h2>5. Your Content & Conduct</h2>
					<p>
						Vocal Reply allows you to create content (voice-to-text messages). You are solely responsible
						for the content you create and ensure that it complies with applicable laws. You agree that your
						content does not infringe on any intellectual property rights or contain illegal or
						inappropriate material.
					</p>
					<p>
						You may not use the Service to upload or distribute any unlawful, offensive, or inappropriate
						content. We are not responsible for monitoring the content you provide, but we reserve the right
						to remove any content that violates these Terms.
					</p>
				</article>

				<article>
					<h2>6. Materials</h2>
					<p>
						The Vocal Reply logo, designs, text, graphics, and other content (excluding your content) are
						owned by us and are protected by intellectual property laws. We grant you the right to use the
						Service, but not to modify, distribute, or create derivative works from it.
					</p>
					<p>
						Any unauthorized use of the Service or its content may result in termination of your access to
						the Service.
					</p>
				</article>

				<article>
					<h2>7. Hyperlinks and Third-Party Content</h2>
					<p>
						You may create a hyperlink to the Service, but you may not use our trademarks or proprietary
						information without our express written consent. We make no claim regarding third-party websites
						or services accessible via links from the Service, and your use of those websites is governed by
						their respective terms.
					</p>
				</article>

				<article>
					<h2>8. Unavoidable Legal Stuff</h2>
					<p>
						THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE DISCLAIM ALL WARRANTIES,
						INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
					</p>
					<p>
						TO THE MAXIMUM EXTENT PERMITTED BY LAW, Vocal Reply AND ITS OWNERS WILL NOT BE LIABLE FOR ANY
						INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH
						YOUR USE OF THE SERVICE.
					</p>
				</article>

				<article>
					<h2>9. Copyright Complaints</h2>
					<p>
						We respect intellectual property rights. If you believe your copyright is being infringed on the
						Service, please contact us in accordance with the Digital Millennium Copyright Act (“DMCA”) and
						applicable law.
					</p>
				</article>

				<article>
					<h2>10. Entire Agreement</h2>
					<p>
						These Terms constitute the entire agreement between you and Vocal Reply regarding your use of
						the Service and supersede any prior agreements.
					</p>
				</article>

				<article>
					<h2>11. Feedback</h2>
					<p>
						We welcome your feedback on the Service. By providing feedback, you grant us the right to use it
						for any purpose without compensation to you.
					</p>
				</article>

				<article>
					<h2>12. Questions & Contact Information</h2>
					<p>
						If you have any questions about these Terms, please contact us at:{" "}
						<a href="mailto:web.notaduo@gmail.com">web.notaduo@gmail.com</a>.
					</p>
				</article>
			</article>
			<Footer />
		</div>
	);
};

export { VocalReplyTermsOfService, VocalReplyTermsOfServiceContent };
