import React from "react";
import { Link, useLocation } from "react-router-dom";
import useDeviceIsMobile from "./hooks/useDeviceIsMobile";
// import OctoOcto from './OCTOOCTO.png'

export default function Footer() {
	const isMobile = useDeviceIsMobile();
	const location = useLocation();

	// Determine the base path
	const basePath = "/vocalreply";
	const onHomePage = location.pathname === basePath || location.pathname === `${basePath}/`;
	const onLegalPages =
		location.pathname === `${basePath}/legal` ||
		location.pathname === `${basePath}/legal/` ||
		location.pathname === `${basePath}/cookiepolicy` ||
		location.pathname === `${basePath}/termsofservice` ||
		location.pathname === `${basePath}/privacypolicy`;

	// Determine if we're on the home page or legal page
	// const isHomePage = location.pathname === basePath;

	return (
		<footer
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center",
				padding: "20px",
			}}
		>
			<nav style={{ marginBottom: "10px" }}>
				{onHomePage && (
					<Link to={`${basePath}/legal`} style={{ margin: "0 10px", color: "#000000" }}>
						Legal
					</Link>
				)}
				{onLegalPages && (
					<Link to={`${basePath}`} style={{ margin: "0 10px", color: "#000000" }}>
						Home
					</Link>
				)}
			</nav>
			{/* <div className="octo-octo-logo">
          <a href="https://octoocto.net">
            <img src={OctoOcto} alt="contact"></img>
          </a>
        </div> */}
			<div className="rights" style={{ marginTop: "10px" }}>
				&copy; 2024 Octo Octo. {isMobile && <br />}
				All rights reserved.
			</div>
		</footer>
	);
}
