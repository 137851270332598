import React from "react";
import Footer from "../Footer";
import { VocalReplyPrivacyPolicyContent } from "./VocalReplyPrivacyPolicy";
import { VocalReplyCookiePolicyContent } from "./VocalReplyCookiePolicy";
import { VocalReplyTermsOfServiceContent } from "./VocalReplyTermsOfService";

function VocalReplyLegal() {
	return (
		<main className="legal">
			<br />
			<h1>Vocal Reply Legal</h1>
			<br />
			<br />
			<section className="card">
				<div className="card-text">
					<details>
						<summary>
							<h2>Cookie Policy for Vocal Reply</h2>
						</summary>
						<VocalReplyCookiePolicyContent />
					</details>
				</div>
			</section>

			<section className="card">
				<div className="card-text">
					<details>
						<summary>
							<h2>Privacy Policy for Vocal Reply</h2>
						</summary>
						<VocalReplyPrivacyPolicyContent />
					</details>
				</div>
			</section>

			<section className="card">
				<div className="card-text">
					<details>
						<summary>
							<h2>Terms of Service for Vocal Reply</h2>
						</summary>
						<VocalReplyTermsOfServiceContent />
					</details>
				</div>
			</section>

			<section className="card">
				<div className="card-text">
					<details>
						<summary>
							<h2>Imprint</h2>
						</summary>
						<a href="mailto:web.notaduo@gmail.com">web.notaduo@gmail.com</a>
					</details>
				</div>
			</section>

			<Footer />

			{/* <section className="card">
				<div className="card-text">
					<details>
						<summary>
							<h2>About Us</h2>
						</summary>
						<AboutUsContent />
					</details>
				</div>
			</section> */}
		</main>
	);
}

export default VocalReplyLegal;
