import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import LandingPageApp from "../Images/LandingpageNotaduo.png";
// import "../styles/style.css";
// import { LOGO } from "../data/constants/images";

function VocalReply() {
	useEffect(() => {
		const animationsPlayedSchools = localStorage.getItem("animationsPlayedHome");
		if (animationsPlayedSchools) return;

		localStorage.setItem("animationsPlayedHome", "true");
	}, []);

	const buttonContainerStyle = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: "10px",
		marginBottom: "3em",
	};

	const baseButtonStyle = {
		margin: "0",
		background: "black",
		color: "hsl(220,27%,96%)",
		fontSize: "1em",
		fontWeight: "600",
		transition: "0.5s",
		boxShadow: "inset rgba(17,17,26,0.1) 0px 0px 10px, inset rgba(17,17,26,0.1) 0px 0px 80px",
		width: "200px",
		padding: "1em 1.5em",
		borderRadius: "0.5em",
		border: "none",
		fontFamily: "Poppins, sans-serif",
		cursor: "pointer",
		textAlign: "center",
	};

	// const [isHoveredWebVersion, setIsHoveredWebVersion] = useState(false);
	// const [isActiveWebVersion, setIsActiveWebVersion] = useState(false);

	const [isHoveredAppStore, setIsHoveredAppStore] = useState(false);
	const [isActiveAppStore, setIsActiveAppStore] = useState(false);

	// const [isHoveredPlayStore, setIsHoveredPlayStore] = useState(false);
	// const [isActivePlayStore, setIsActivePlayStore] = useState(false);

	const getButtonStyle = (isHovered, isActive) => ({
		...baseButtonStyle,
		...(isHovered && {
			background: "linear-gradient(145deg, hsl(185,50%,60%) 0%, hsl(230,50%,60%) 100%)",
			boxShadow: "none",
		}),
		...(isActive && {
			background: "linear-gradient(145deg, hsl(185,50%,55%) 0%, hsl(230,50%,60%) 100%)",
			boxShadow:
				"inset rgba(17,17,26,0.5) 0px 0px 2px, inset rgba(17,17,26,0.1) 0px 0px 4px, inset rgba(17,17,26,0.05) 0px 0px 8px",
			transform: "scale(0.99)",
			transition: "0.1s",
		}),
	});

	return (
		<main className="home" style={{ marginTop: "-40px", marginBottom: "-50px" }}>
			<div
				className="hero-text"
				style={{
					textAlign: "center",
					padding: "20px 20px 20px 20px",
					backgroundColor: "#ffffff",
					borderRadius: "8px",
					marginBottom: "10px",
				}}
			>
				<br />
				<br />
				<h1 style={{ color: "#000000", fontSize: "2.5em" }}>Vocal Reply</h1>
				{/* <br /> */}
				<p style={{ color: "#000000", fontSize: "1.25em" }}>Coming Soon:</p>
				<p style={{ color: "#000000", fontSize: "1.25em" }}>Smart dictation of your message</p>
			</div>
			<br />
			<br />
			<section className="features" style={{ marginTop: "-50px" }}>
				<div className="feature">
					<img className="screenshot" src={LandingPageApp} alt="screenshot of the app's home page" />
				</div>
			</section>
			<br />
			{/* <br /> */}
			<div
				className="button-container"
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					gap: "10px",
				}}
			>
				<div style={buttonContainerStyle}>
					{/* <button
						// onClick={() =>
						// 	(window.location.href = "https://www.notaduo.com")
						// }
						style={getButtonStyle(isHoveredWebVersion, isActiveWebVersion)}
						onMouseEnter={() => setIsHoveredWebVersion(true)}
						onMouseLeave={() => setIsHoveredWebVersion(false)}
						onMouseDown={() => setIsActiveWebVersion(true)}
						onMouseUp={() => setIsActiveWebVersion(false)}
					>
						Web Version
					</button> */}

					<button
						// onClick={() =>
						// 	(window.location.href = "https://apps.apple.com/us/app/notaduo/id1639505053?l=en")
						// }
						style={getButtonStyle(isHoveredAppStore, isActiveAppStore)}
						onMouseEnter={() => setIsHoveredAppStore(true)}
						onMouseLeave={() => setIsHoveredAppStore(false)}
						onMouseDown={() => setIsActiveAppStore(true)}
						onMouseUp={() => setIsActiveAppStore(false)}
					>
						App Store
					</button>

					{/* <button
						// onClick={() =>
						// 	(window.location.href = "https://play.google.com/store/apps/details?id=com.example.notaduo&hl=en_US&gl=US")
						// }
						style={getButtonStyle(isHoveredPlayStore, isActivePlayStore)}
						onMouseEnter={() => setIsHoveredPlayStore(true)}
						onMouseLeave={() => setIsHoveredPlayStore(false)}
						onMouseDown={() => setIsActivePlayStore(true)}
						onMouseUp={() => setIsActivePlayStore(false)}
					>
						Play Store
					</button> */}
				</div>
				<Footer />
			</div>
		</main>
	);
}

export default VocalReply;
