import React from "react";
import Footer from "../Footer";

const VocalReplyCookiePolicy = () => {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>Cookie Policy for Vocal Reply</h1>
					<VocalReplyCookiePolicyContent />
				</div>
			</section>
		</main>
	);
};

const VocalReplyCookiePolicyContent = () => {
	return (
		<div className="legal">
			<div className="legal-header">
				<p>
					<strong>Last Revised:</strong> 01 November 2024
				</p>
			</div>
			<article>
				<h2>1. What Are Cookies?</h2>
				<p>
					Cookies are small pieces of data stored on your device (computer, smartphone, etc.) when you visit
					our website. They enable us to recognize you on subsequent visits, remember your preferences, and
					offer a personalized experience.
				</p>
			</article>

			<article>
				<h2>2. How We Use Cookies</h2>
				<p>At Notaduo, we utilize cookies for various purposes:</p>
				<ul>
					<li>
						Preferences: To recall settings and preferences you've chosen, such as language or user
						interface themes, providing a more customized experience.
					</li>
					<li>
						Performance and Analytics: We employ cookies to analyze user engagement with our platform,
						aiding in its enhancement and optimization.
					</li>
					<li>
						Advertising: We may use cookies to display advertisements that are relevant to your interests
						and browsing history.
					</li>
					{/* <li>
						Backend Services: Cookies facilitate certain backend functionalities, including user
						authentication and data storage, for a seamless experience.
					</li> */}
				</ul>
			</article>

			<article>
				<h2>3. Types of Cookies We Use</h2>
				<ul>
					<li>Session Cookies: These are temporary and get deleted once you close your browser.</li>
					<li>Persistent Cookies: These remain on your device until they expire or are removed by you.</li>
					<li>Essential Cookies: Necessary for the basic functioning and navigation of our platform.</li>
					<li>
						Performance and Analytics Cookies: Employed to track platform performance and user interactions.
					</li>
					<li>Advertising Cookies: Used for delivering targeted advertising content.</li>
					<li>Security Cookies: Implemented to protect your information and reduce security threats.</li>
					<li>
						Site Management Cookies: Essential for preserving your session and preferences during your
						visit.
					</li>
					<li>
						Third-party Cookies: We may partner with third-party services that set cookies to enhance your
						user experience.
					</li>
				</ul>
			</article>

			<article>
				<h2>4. Managing and Disabling Cookies</h2>
				<p>
					You have control over the use of cookies at the browser level. Disabling cookies might limit your
					access to certain functionalities on our platform. For guidance on managing cookies, please refer to
					the following links:
				</p>
				<ul>
					<li>
						<a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
							Apple Safari
						</a>
					</li>
					<li>
						<a href="https://support.google.com/chrome/answer/95647">Google Chrome</a>
					</li>
					<li>
						<a href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies">
							Microsoft Edge
						</a>
					</li>
					<li>
						<a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
							Microsoft Internet Explorer
						</a>
					</li>
					<li>
						<a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
							Mozilla Firefox
						</a>
					</li>
					<li>
						<a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a>
					</li>
					<li>
						<a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid">
							Android (Chrome)
						</a>
					</li>
					<li>
						<a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS">
							iPhone or iPad (Chrome)
						</a>
					</li>
					<li>
						<a href="https://support.apple.com/en-us/HT201265">iPhone or iPad (Safari)</a>
					</li>
				</ul>
			</article>

			<article>
				<h2>5. Changes to This Cookie Policy</h2>
				<p>
					We reserve the right to modify this policy to align with new practices or for legal, operational, or
					regulatory reasons. Please review this policy periodically to stay informed of any changes.
				</p>
			</article>

			<article>
				<h2>6. Contact Us</h2>
				<p>
					For questions regarding our cookie usage or other technologies, please contact us at{" "}
					<a href="mailto:web.notaduo@gmail.com">web.notaduo@gmail.com</a>.
				</p>
				<p>
					Refer to our <a href="/vocalreply/privacypolicy">Privacy Policy</a> for more details on data
					collection and usage.
				</p>
			</article>
			<Footer />
		</div>
	);
};

export { VocalReplyCookiePolicy, VocalReplyCookiePolicyContent };
