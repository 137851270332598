import React from "react";
import Footer from "../Footer";

const VocalReplyPrivacyPolicy = () => {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>Privacy Policy for Vocal Reply</h1>
					<VocalReplyPrivacyPolicyContent />
				</div>
			</section>
		</main>
	);
};

const VocalReplyPrivacyPolicyContent = () => {
	return (
		<div className="legal">
			<div className="legal-header">
				<p>
					<strong>Last Revised:</strong> 01 November 2024
				</p>
			</div>
			<article>
				<h2>Our Privacy Policy</h2>
				<p>
					This privacy policy applies to information we collect when you use Vocal Reply. Vocal Reply is an
					iOS application that allows users to speak into their phones and uses OpenAI’s Whisper and
					Completion APIs to generate text messages from voice input. We prioritize the protection of your
					personal information and ensure that your data is handled securely.
				</p>
			</article>
			<article>
				<h2>Collection and Use of Information</h2>
				<p>
					Vocal Reply uses your voice input to generate text messages. Audio data is processed by OpenAI's
					Whisper API, and once processed, the audio is deleted immediately. We do not store or retain your
					voice recordings. The generated text is temporarily stored for the sole purpose of delivering the
					message and is not used for any other purpose.
				</p>
				<p>
					We use Firebase Auth to authenticate users securely and Firebase Analytics to gather non-personal,
					aggregated data for improving app performance. Firebase Analytics is used without an Advertising ID
					to ensure your privacy.
				</p>
			</article>
			<article>
				<h2>Third-Party Services</h2>
				<p>Vocal Reply integrates with various third-party services to enhance functionality:</p>
				<ul>
					<li>
						<strong>Firebase Auth:</strong> Used for user authentication and secure login.
					</li>
					<li>
						<strong>Firebase Cloud Messaging:</strong> Sends notifications about app updates and new
						features.
					</li>
					<li>
						<strong>RevenueCat:</strong> Manages subscriptions and in-app purchases.
					</li>
				</ul>
				<p>
					We also use German-certified hosting for our backend services to ensure high data protection
					standards. Only login-related information is stored in Firebase, while other personal data is
					handled with care.
				</p>
			</article>
			<article>
				<h2>Use of OpenAI APIs</h2>
				<p>
					Vocal Reply relies on OpenAI's Whisper and Completion APIs for transcribing voice to text and
					enhancing the generated messages. The audio transcription process is fully automated, and your audio
					input is deleted immediately after the text is generated. No audio data is stored or used to train
					OpenAI models.
				</p>
			</article>
			<article>
				<h2>Data Security</h2>
				<p>
					We implement robust security measures to protect your data, including encryption and secure access
					protocols. However, while we strive to protect your information, no system is completely secure. We
					advise users to be mindful of the sensitivity of the information shared through the app.
				</p>
			</article>
			<article>
				<h2>Changes to This Privacy Policy</h2>
				<p>
					We may update this privacy policy from time to time to reflect changes in our practices or legal
					obligations. We encourage you to review this policy periodically. Your continued use of Vocal Reply
					after any changes signifies your acceptance of the updated policy.
				</p>
			</article>
			<article>
				<h2>Contact Us</h2>
				<p>
					If you have any questions about this privacy policy or our data practices, please contact us at:{" "}
					<a href="mailto:web.notaduo@gmail.com">web.notaduo@gmail.com</a>.
				</p>
			</article>
			<Footer />
		</div>
	);
};

export { VocalReplyPrivacyPolicy, VocalReplyPrivacyPolicyContent };
