import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import VocalReply from "./VocalReply/VocalReply";
import VocalReplyLegal from "./VocalReply/VocalReplyLegal";
import { VocalReplyPrivacyPolicy } from "./VocalReply/VocalReplyPrivacyPolicy";
import { VocalReplyCookiePolicy } from "./VocalReply/VocalReplyCookiePolicy";
import { VocalReplyTermsOfService } from "./VocalReply/VocalReplyTermsOfService";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />} />

				<Route path="/vocalreply" element={<VocalReply />} />
				<Route path="/vocalreply/legal" element={<VocalReplyLegal />} />
				<Route path="/vocalreply/privacypolicy" element={<VocalReplyPrivacyPolicy />} />
				<Route path="/vocalreply/cookiepolicy" element={<VocalReplyCookiePolicy />} />
				<Route path="/vocalreply/termsofservice" element={<VocalReplyTermsOfService />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);

reportWebVitals();
